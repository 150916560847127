input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.App {
  text-align: center;
  /* padding-top: 120px; */
  position: relative;
}

.MuiFormHelperText-root {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

header {
  margin-top: 0px !important;
}

.video-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;
  background-image: url("/public/assets/video_fallback.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* Make the video cover the entire screen */
.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  /* Ensures the video covers the screen without distortion */
  transform: translate(-50%, -50%);
}